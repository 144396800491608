:root {
    --primary-color: #ff6666;
    --swiper-navigation-size: 30px !important;
    --orange: #FF8345;
    --yellow: #F7D94C;
    
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 0.5px;
    font-family: "Noto Sans TC", serif;
}



a {
    text-decoration: none;
    color: inherit;
}

header {

    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 0 5%;
    position: sticky;
    top: 0;
    background: #fff;
    box-shadow: 0px 1px 8px 1px #d7d7d7;
    z-index: 5;
   

    @media (max-width: 768px) {
        height: 80px;
        
   }


    .header-logo {
        width: clamp(200px, 13.020vw, 250px); 


        
        img {

            width: 100%;
            object-fit: contain;
        }
        
    }
}

nav {

    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
   

    @media (max-width: 768px) {
         position: absolute;  
         width: 80%;
         background: #fff;
         right: 0;
         height: 100dvh;
         top: 0;
         justify-content: center;
         box-shadow: 0px 1px 8px 1px #d7d7d7;
         transform: translateX(100%);
         opacity: 0;
         display: none;
         
         
         

    }
}

nav.active {
    transform: translateX(0);
    opacity: 1;
    display: block;
  
}

nav.transition {
    transition: opacity .3s ease,
    display .3s ease allow-discrete, transform .3s ease;
  
}

@starting-style { 
    nav.active {
      opacity: 0;
      transform: translateX(100%);
    }
}

.main-nav {

    display: flex;
    list-style: none;
    gap: 35px;
    height: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        padding-top: 100px;
        

   }

    

    .main-nav-item {
        transition: all 0.3s ease;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        font-weight: 600;

        @media (max-width: 768px) {
            flex-direction: column;
            height: auto;
            align-items: flex-start;

            a {
                padding-left: 30px;
                width: 100%;
                display: flex;
            }
            
    
       }

       


        
    }

    .main-nav-item:hover .main-nav-item-link {
        color: var(--primary-color); 
    }
    
    .main-nav-item:hover .sub-nav,
    .main-nav-item .sub-nav:hover {
        display: block; 
    }

    .sub-nav {
        display: none;
        list-style: none;
        position: absolute;
        top: 100%;
        box-shadow: 0px 1px 8px 1px #d7d7d7;


        @media (max-width: 768px) {
            display: block;
            position: static;
            box-shadow: none;
            width: 100%;
            padding-top: 15px;
            
        }

        .sub-nav-item {

            padding: 10px 20px;
            background: #fff;
            border-bottom: 1px solid #eee;
            font-size: 16px;
            white-space: nowrap;


            &:hover > a {
                color: var(--primary-color);
            }

            @media (max-width: 768px) {
                display: block;
                
            }
        }
   
    
    }

    

    

  
}

.mob-nav {

    display: none;

    @media (max-width: 768px) {
        display: block;
         
     }


    .mob-nav-icon{

        width: 50px;
        height: 50px;
        cursor: pointer;
        position: relative;
  
        span {

            position: absolute;
            background: var(--primary-color);
            display: inline-block;
            border-radius: 3px;
            left: 12px;
            height: 3px;
            transition: all .4s;
            width: 50%;
            &:nth-of-type(1) {
                top: 16px;
            }
            &:nth-of-type(2) {
                top: 24px;
            }
            &:nth-of-type(3) {
                top: 32px;
            }
        }

    }

    .mob-nav-icon.active span {
        &:nth-of-type(1) {
            width: 45%;
            top: 18px;
            left: 14px;
            transform: translateY(6px) rotate(-45deg);
        }
        &:nth-of-type(2) {
            opacity: 0;
        }
        &:nth-of-type(3) {
            width: 45%;
            top: 30px;
            left: 14px;
            transform: translateY(-6px) rotate(45deg);
        }
    }
}


footer {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #fff;
    

    .footer-container {

        display: flex;
        width: 85%;
        justify-content: space-between;
        padding: 20px 0;


        @media (max-width: 1200px) {
            width: 90%;
            align-items: center;
            flex-direction: column;
            gap: 15px;
             
         }



        .fb-logo {

            @media (max-width: 1200px) {
                width: 250px;
                 
             }

            
            img {
                width: 100%;
                object-fit: contain;
                display: block;
            }
        }
    
        .footer-detail {
    
            display: flex;
            font-weight: 500;
            align-items: center;

            @media (max-width: 768px) {
                font-size: 14px;
            }

           
            
    
            
    
           
            .footer-nav {
                    
                    display: flex;
                    list-style: none;

                    /* @media (max-width: 768px) {
                        padding-top: 5px;
                    } */
                 

                    
        
                    li {
        
                        color: #333;

                        
    
    
                    }
    
                    li::before {
                        content: '|';
                        margin: 0 10px;
                 
                        
                    }

                    /* @media (max-width: 1200px) {
                        li:first-child::before {
                            content: '';
                            margin: 0;
                        }
                    } */
                    
            }
        }
    }

    .footer-copyright{

        padding: 20px 0;
        box-shadow: 0px 1px 8px 1px #d7d7d7;
        width: 100%;
        text-align: center;

        @media (max-width: 768px) {
            
          
            font-size: 12px;
             
         }
    }

   
}