

.section-container {
    width: 90%;
    
}

.section-title {

    margin: 50px 0;
    font-size: clamp(30px, 2.5vw, 48px);
    /* color: var(--primary-color); */
}

#hero {

    min-height: calc(100svh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('./images/background.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 768px) {
        min-height: calc(100svh - 80px);
        
   }
    

    .hero-container {
        display: flex;
        align-items: center;
        gap: 30px;

        @media (max-width: 1200px) {
            flex-direction: column;
            padding: 50px 0;
        }

       
    

        .hero-intro {

            width: 45%;
            display: flex;
            flex-direction: column;
            

            @media (max-width: 1200px) {
                width: 100%;
            }

            .hero-intro-container {



                @media (max-width: 1200px) {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;
                }

                @media (max-width: 768px) {
                    flex-direction: column;
                }
            }

            .hero-award {

                width: 500px;

                @media (max-width: 1200px) {
                    width: 50%;
                }
                

                @media (max-width: 768px) {
                    /* width: 200px; */
                    width: 85%;
                    margin-left: auto;
                    order: 2;
                    margin: -20px 0 -10px auto;
                    

                }


                img {
                    width: 100%;
                    object-fit: contain;
                }
            }

            .hero-slogan {
                

                @media (max-width: 768px) {
                    order: 1;
                    

                }
               
            }

            .hero-main-slogan {

                display: flex;
                font-weight: 700;
                display: flex;
                align-items: baseline;
                gap: 10px;
               

                .title {
                    font-size: clamp(48px, 2.5vw, 50px);
                    color: var(--primary-color);
                }

                .text {
               
                    font-size: clamp(22px, 1.5625vw, 30px);
                }
            }

            .hero-sub-slogan {
                font-weight: 700;
                font-size: clamp(20px, 1.5625vw, 30px);
             


            }

            .line {
                width: clamp(80px, 6.25vw, 120px);
                height: 10px;
                margin: 20px 0;
                background: var(--primary-color);
                
            }

            .hero-download {

                display: flex;
                align-items: center;
                gap: 40px;

                @media (max-width: 768px) {
                    flex-direction: column-reverse;
                    order: 4;
                }

                .app-images {

                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .app-link {
                        width: 200px;
                        box-shadow: 2px 2px 5px #646464;
                        border-radius: 6px;
                        display: inline-flex;
                        background: #fff;
                        transition: all 0.3s ease;

                        img {
                            width: 100%;
                            object-fit: contain;
                        }

                        &:hover {
                            box-shadow: 2px 2px 5px var(--primary-color);
                        }
                    }
   
                }

                .qrcode {

                    width: clamp(180px, 10.41667vw, 200px);

                    img {
                        width: 100%;
                        object-fit: cover;
                    }

                    @media (max-width: 768px) {
                        width: 220px;
                    }
                }
                
            }


        }

        .hero-phone-mob {

            display: none;
            padding-bottom: 20px;

            @media (max-width: 768px) {
                display: block;
                width: 100%;
                order: 3;
                
            }

            img {
                    
                width: 100%;
                object-fit: contain;
            }
        }

        .hero-phone {

            width: 65%;

            @media (max-width: 1200px) {
                width: 100%;
            }

            @media (max-width: 768px) {
                display: none
            }

            img {
                    
                width: 100%;
                object-fit: contain;
            }
        }
    }
}

#features {

    min-height: 65vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        padding: 50px 0;
    }
 

    .features-container {

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 50px;
        width: 70%;

        @media (max-width: 1200px) {
            width: 80%;
        }

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
            width: 80%;
        }

        .feature {

           

            .feature-icon {

                width: 120px;
                height: 120px;

                img {
                    width: 100%;
                    object-fit: contain;
                }
            }

            .line {

                width: clamp(60px, 3.125vw, 100px);
                height: 8px;
                margin: 20px 0 10px 0;
                background: var(--primary-color);
            }

            .feature-title {
                font-size: 18px;
                font-weight: 700;
                padding-top: 5px;
            }
        }

       
    }

}

#products {

    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 100px;
   

    .products-container {

        position: relative;
        width: 80%;
    }

    #products_wrapper {

        padding-bottom: 100px;
    }
    

    .products_card {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 6px;
        overflow: hidden;
        height: 100%;
        flex: 1;
        box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
        width: 100%;
        cursor: pointer;
      }
      
      .products_cardImg {
        width: 100%;
        height: 300px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
       
       
      }
      
      .products_cardImg > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
       
      }
      
      .products_Info {
        display: flex;
        height: calc(100% - 300px);
        min-height: 130px;
        align-self: stretch;
        justify-content: space-evenly;
        flex-direction: column;
        padding: 10px 15px;
        width: auto;
      }
      
      .products_Price {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        padding: 10px 0;
        color: var(--primary-color);
        margin-top: auto;
       
      }
      
      .products_Name {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 800;
        line-height: 1.5em;
       
      
      }
      
      .products_City {
         display: flex;
         flex-wrap: wrap;
         padding: 5px 0;
         color: #a0a3a7;
         line-height: 1.5em;
        
      }

      .products_ReadMore {

        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 1px solid  var(--primary-color);
        background-color: var(--primary-color);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      
.products_ReadMore i {

    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  #products_ReadMoreContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    
  }
  
  .products_ReadMoreText {
  
    padding: 10px 0;
    color: var(--primary-color);
   
  }
  
  .products_ReadMoreButtonWrapper {
   
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 450px;
    width: 100%;
   
  }
      

}

#milestone {

    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 80vh;
    padding-bottom: 100px;

    @media (max-width: 1200px) {
       min-height: auto;
        
    }
  

    .milestone-container {

        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80%;
     
        
    }

   

    .milestone-item {

        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;

        

        .milestone-item-container {

            padding: 15px;
            margin-bottom: 25px;
            border-radius: 5px;
            background: #fff1f1;
            height: 100%;
            position: relative;
            width: 100%;
            

            &::after {
                content: '';
                width: 0;
                height: 0;
                bottom: -10px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid #fff1f1; 
            }
        }
       
        
        .milestone-year {
            width: 100%;
            border-left: 6px solid var(--primary-color);
            padding-left: 10px;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 10px;
        }


          
        

        .milestone-image {
           
            width: 100%;
            height: 150px;
            display: flex;
            align-items: center;
            overflow: hidden;
            background: #fff;
            padding: 10px;



            img {
                height: 100%;
                width: 100%;
                object-fit: contain;

            }
          }

          /* .milestone-text {
         
            padding: 10px 10px 15px 10px
          } */

          .milestone-time {

            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-top: auto;
            flex-direction: column;
           

            /* &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                position: absolute;
                top: 50%;
                background-color: red;
                transform: translateX(100%);
            } */

            &::before {
                content: '';
                position: absolute;
                width: calc(100% + 20px);
                height: 1px;
                position: absolute;
                top: 50%;
                background-color: #eeeeee;
            }

            .milestone-year {
                background-color: white;
            }

            .milestone-month {
                background-color: var(--primary-color);
                position: relative;
                z-index: 2;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 14px;
                
                span {
                    font-size: 10px;
                    padding-left: 1px;
                    padding-top: 3px;
                }
            }

           
          }

          .milestone-description {

            text-align: justify;
            font-size: 14px;
            padding-top: 15px;

            span {
                color: var(--primary-color);
            }
          }

                       
         



         

    }


   
}

#activites {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    padding-bottom: 100px;


    .activites-container {

        width: 80%;

        .activites-content {

            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            gap: 40px;

            @media (max-width: 768px) {
                grid-template-columns: 1fr
            }


            .activity {

                
                position: relative;
                display: flex;
                flex-direction: column;
             
                /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */
                

                .activity-image {

                    height: clamp(200px, 15.5625vw, 300px);
                    

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .activity-text {

    
                  display: flex;
                  flex-direction: column;
                  flex: 1;
                  justify-content: space-between;


                  

                    .activity-title {

                        background: var(--primary-color);
                        
                        color: #fff;
                        padding: 10px 15px;
                        position: absolute;
                        top: -5px;
                        left: -6px;
                       

        
                    }

                    .activity-title::before {
                        position: absolute;
                        content: '';
                        top: 100%;
                        left: 0;
                        border: none;
                        border-bottom: solid 10px transparent;
                        border-right: solid 20px #bb4545;
                    }

                    .activity-description {
                       padding: 10px 0;
                    }


              

                    .activity-time {

                        margin-top: auto;
                        color: #91989F;
                        
                    }
                }
            }


        }
    }

}

#news {

    min-height: 100vh;
    display: flex;
    flex-direction: column; 
    align-items: center;
    padding-bottom: 100px;

    .news-container {

        display: flex;
        gap: 40px;
        flex-direction: column;
        width: 80%;

        

        .news {

            display: flex;
            gap: 20px;

            @media (max-width: 768px) {
                flex-direction: column;
            }

            .news-image {

                width: 20%;
                height: auto;
                display: inline-flex;
               

                @media (max-width: 1200px) {
                    width: 40%;
                    align-items: flex-start;

                }

                @media (max-width: 768px) {
                    width: 100%;
                   
                }
                

                img {
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                    
                }
            }

            .news-content {
                display: flex;
                flex-direction: column;
                width: 80%;
                flex: 1;

                @media (max-width: 768px) {
                    width: 100%;
                }

                .news-name-container {
                        
                        display: flex;
                        gap: 10px;
                        align-items: baseline;
    
                        .news-name {
    
                            font-size: 24px;
                            font-weight: 800;
                        }
    
                        .news-date {
    
                            color: #91989F;
                            font-size: 14px;
                        }
                }

                .news-title {
                        
                        font-size: clamp(18px, 1.145833vw, 22px);
                        margin: 5px 0 15px 0;
                        color: var(--primary-color);
                }

                .news-description {

                    font-size: 16px;
                    text-align: justify;
                    margin-bottom: 10px;
                }

                .news-link {

         
                    margin-top: auto;
                    margin-left: auto;
                    width: fit-content;
                    padding: 6px 20px;
                    font-size: 14px;
                    border-radius: 3px;
                    
                    color: var(--primary-color);
                    border: 1px solid var(--primary-color);
                    transition: all 0.3s ease;

                    &:hover {
                        background: var(--primary-color);
                        color: white;
                    }
                    
                    
                }
            }


        }

    }
}

#videos {

    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 100px;

    @media (max-width: 1200px) {
        min-height: auto;
    }

    .videos-container {


        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        gap: 50px;
        width: 80%;

        @media (max-width: 768px) {
            grid-template-columns: 1fr
        }

        .video {
          

            &:hover img {
                transform: scale(1.1);
                
            }

    

            .video-image {

               width: 100%;
               border-radius: 10px;
               overflow: hidden;
               display: inline-flex;
            

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 0.3s ease;
                    
                }

                
            }

            .video-text {

            
                display: flex;
                flex-direction: column;
                flex: 1;
                justify-content: space-between;
                margin-top: 10px;

               

            }
        }

    }
}

#development {

    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 100px;

    @media (max-width: 1200px) {
        min-height: auto;
    }

    .development-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .tab-container {

            display: flex;
            border: 1px solid var(--primary-color);
            border-radius: 3px;
            width: fit-content;

            .tab-button {
                padding: 6px clamp(20px, 2.604vw, 50px);
                border: none;
                border-right: 1px solid var(--primary-color);
                color: var(--primary-color);
                background-color: transparent;
                cursor: pointer;
                font-size: 16px;

                &:last-child {
                    border-right: none;
                }
            }

            .tab-button.active {
                background-color: var(--primary-color);
                color: white;
                display: flex;
                justify-content: center;
            }
        }

        .tab-content {

            display: flex;
            justify-content: center;
            width: 80%;

            @media (max-width: 1200px) {
                width: 100%;
            }

    

            

            

            .tab-pane {
                display: none;
                width: 80%;

                @media (max-width: 768px) {
                   width: 85%;
                }

                

                .tab-pane-container {
                    display: flex;
                    gap: 40px;

                    .corporate {

                       
                        display: flex;
                        justify-content: space-between;
                        gap: 30px;

                        @media (max-width: 768px) {
                            flex-direction: column-reverse;
                        }

                        .corporate-image {

                            width: 50%;
                            height: 100%;

                            @media (max-width: 768px) {
                                width: 100%;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .corporate-content {

                            padding-top: 2vw;
                            width: 50%;


                            @media (max-width: 768px) {
                                width: 100%;
                            }

                            .corporate-title {

                                font-size: clamp(25px, 2.083vw, 40px);
                                font-weight: 700;
                                color: var(--primary-color);
                                line-height: 1.4;
                                padding-bottom: 20px;

                                span {
                                    color: #01B468;
                                    padding-left: 5px;
                                    font-size: clamp(30px, 2.604vw, 50px);
                                }
                            }

                            .corporate-subtitle {
                                font-size: clamp(16px, 2vw, 30px);
                                padding-bottom: 15px;
                            }

                            .corporate-description {

                                ul {

                                    line-height: 1.5;
                                    font-weight: 500;
                                    padding-left: 20px;
                                }
                            }
                        }
                    }

                    .events {
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        gap: 20px;

                        @media (max-width: 768px) {
                            grid-template-columns: 1fr;
                            gap: 40px;
                        }
                    }

                    .event {


                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        flex: 1;
                        gap: 20px;
                        

                        .event-image {

                            width: 100%;
                            height: 80%;
                            border-radius: 5px;
                            overflow: hidden;
                            

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .event-text {

                            display: flex;
                            flex-direction: column;
                            position: relative;
                            border-bottom: 1px solid var(--primary-color);
                            padding:  0 0 10px 0;
                            font-weight: 700;

                            &::before {
                                content: "";
                                display: block;
                                position: absolute;
                                bottom: -14px;
                                right: 10px;
                                width: 1px;
                                height: 80%;
                                background: var(--primary-color);
                            }

                            .event-time {

                     
                                font-size: clamp(16px, 1.041vw ,20px);
                                padding-bottom: 5px;
                                color: var(--primary-color);
                                font-weight: 400;
                             
                            }
                            .event-description {

                               width: 85%;
                              
                            }
                        }

                        
                    }

                    .event:nth-child(odd) {
                        flex-direction: column-reverse;

                        @media (max-width: 768px) {
                            flex-direction: column;
                        }
                    }
                }
            }
            
            .tab-pane.active {
                display: block;
                padding-top: 100px;


                @media (max-width: 768px) {
                    padding-top: 50px;
                }
            }
        }
    }
}

#qa {

    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 1200px) {
        min-height: auto;
    }

    @media (max-width: 768px) {
        padding-bottom: 50px;
        
    }

    .qa-container {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .qa-tab-buttons {

            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
            width: 50%;

            @media (max-width: 768px) {
                width: 85%;
                grid-template-columns: repeat(3, 1fr);
            }

            .qa-tab-button {
                
                border: none;
                font-size: clamp(19px, 0.125vw ,24px);
                color: #ccc;
                background-color: transparent;
                cursor: pointer;
                font-weight: 500;
            }

            .qa-tab-button.active {
      
                display: flex;
                justify-content: center;
                color: black;
            }
        }

        .qa-tab-content {

            width: 70%;
            display: flex;
            justify-content: center;

            @media (max-width: 1200px) {
                width: 80%;
                
            }

            @media (max-width: 768px) {
                width: 85%;
                
            }

            

            .qa-tab-pane {
                display: none;

                .concept {
                    display: flex;
                    flex-direction: column;
                    font-size: 18px;
                    font-weight: 600;
                    position: relative;
                    width: 100%;

                    .concept-slogan {
                        font-size: 30px;
                        font-weight: 700;
                        padding: 20px 0;
                        color: var(--primary-color);
                    }

                    .concept-container {

                        display: flex;
                        gap: 40px;


                        @media (max-width: 768px) {
                            flex-direction: column;
                            gap: 20px;
                            
                        }



                        .concept-text {

                            font-size: 16px;
                            text-align: justify;
                            font-size: 18px;
                        }

                        .concept-image {
                            width: clamp(200px, 13.02vw, 250px);

                            @media (max-width: 768px) {
                                margin: auto;
                                
                            }

                            img {
                                width: 100%;
                                object-fit: contain;
                                
                            }
                        }
                    }

                    ul {
                        list-style-position: inside;
                        padding-top: 5px;

                        li {
                            padding: 5px 0;
                        }
                    }

                }

                .question-container {
                    overflow: hidden;
                    
    
                    .question {
    
                        border-bottom: 1px solid #aab1b9;
                        padding: 10px 0;
                        cursor: pointer;
    
                        span {
    
                            font-size: clamp(18px, 1.302vw, 25px);
                            font-weight: 700;
                           
                        }
                    }
    
                    .answer {
    
                        display: grid;
                        grid-template-rows: 0fr;
                        transition: 400ms all ease;
                      
    
                        .answer-container {
    
                            overflow: hidden;
                            padding: 0 20px;
                            display: flex;
                            flex-direction: column;

                            @media (max-width: 768px) {
                                padding: 0;
                            }

                            .answer-emphasize {
                                font-size: 22px;
                                font-weight: 700;
                                padding: 10px 0;
                           
                            }

                            
    
                            a {
    
                                color: var(--primary-color);
                                display: contents;
                            }
                         
    
                            ul {
                             
                                list-style-type: decimal;
                                padding: 0 0 10px 35px;
                                
    
                                li {
    
                                    font-size: 16px;
                                    padding: 5px 0;
    
                                    .list-circle-number {
                                        list-style-type: none;
                                        counter-reset: circle-number;
                                       padding: 10px 0 0 10px;
                                       
    
                                        > li {
                                            position: relative;
                                            padding-left: 30px;
    
                                            &::before {
                                                content: counter(circle-number);
                                                counter-increment: circle-number;
                                                position: absolute;
                                                left: 0;
                                                top: 7px;
                                                width: 18px;
                                                height: 18px;
                                                border-radius: 50%;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                font-size: 12px;
                                                padding-top: 1px;
                                                font-family: Arial;
                                                /* background: var(--primary-color); */
                                                border: 1px solid black;
                                            }

                             
                                        }
                                    }

                                    .list-lower-alpha {

                                        list-style-type: lower-alpha;
                                        padding-left: 20px;
                                    }
                                }
                            }
    

    
                            .answer-slogan {
                                color: var(--orange);
                                font-size: 25px;
                                font-weight: 500;
                                padding-bottom: 10px;
                            }
    
                            .answer-notice {
                                display: flex;
                                align-items: baseline;
                                gap: 5px;
                                padding: 10px 0;
    
                                i {
                                    color: var(--yellow);
                                }
                            }
    
                            .answer-img {
    
                                display: block;
                                margin: 0 auto;
                                width: clamp(200px, 13.02vw, 250px);
                                justify-content: center;
                                padding: 10px 0;
                                
    
                                img {
                                    width: 100%;
                                    object-fit: contain;
                                }
                            }

                            .answer-logo {
                                    
                                    display: inline-flex;
                                    justify-content: center;
                                    width: 100%;
                                    margin: 20px 0;
    
                                    img {
                                        width: 60px;
                                        object-fit: contain;
                                    }
                            }
                        }
                      
    
                        
                    }
                    
                    .answer.active {
                        grid-template-rows: 1fr;
                        padding: 20px 0;
                    }
                }
            }

            .qa-tab-pane.active {
                display: block;
                padding-top: 50px;
            }



            
        }
    }
}

#contact {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 768px) {
        
        height: 100svh;
        
    }

    .contact-container {
        display: flex;
        justify-content: space-between;
        width: 65%;

        @media (max-width: 1200px) {
            flex-direction: column;
            width: 85%;
            gap: 40px;
            
        }

        .contact-detail {

            .contant-title {
                display: flex;
                align-items: center;
                font-size: clamp(25px, 2.1875vw, 42px);
                gap: 20px;
                padding-bottom: 20px;

                .contact-icon {

                    color: var(--primary-color);
                   
                }

            }

            .contact-email {
                font-size: clamp(17px, 1.5625vw, 30px);

                
            }
        }

        .contact-form {

            display: flex;
            flex-direction: column;

            input, textarea, button {
                padding: 10px 15px;
                border: 1px solid var(--primary-color);
                border-radius: 5px;
                width: 100%;

                &:focus {
                    outline: none;
              
                }
            }

            input {
                margin-bottom: 15px;
            }

            textarea {

                border-radius: 5px 5px 0 0; 
                resize: none;
                width: clamp(300px, 26.04vw, 500px);

                @media (max-width: 1200px) {
                    width: 100%;
                    
                }
            }

            input[type="button"] {

               
                color: var(--primary-color);
                cursor: pointer;
                transition: all 0.3s ease;
                background: transparent;
                border-top: none;
                border-radius: 0 0 5px 5px;

                &:hover {
                    background: var(--primary-color);
                    color: white;
                }
            }

            .contact-button {

                display: flex;
                flex-direction: column;
            }

            

            
        }
    }


}


.swiper-button-next, .swiper-button-prev {

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    color: var(--primary-color) !important;
   
}

.swiper-button-next {
    right: -1px !important; /* 調整右箭頭的位置 */
}

.swiper-button-prev {
    left: -1px !important;
}

.mySwiper {

    padding: 0 32px !important;
}

.swiper-slide {

   height: initial !important;
}



.modal {
    display: none;
    position: fixed; 
    z-index: 5; 
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 

    .modal_container {
        background-color: #fefefe;
        padding: 20px;
        height: 40vh;
        border: 1px solid #888;
        display: flex;
        justify-content: center;
        position: relative;
        width: clamp(280px, 85%, 500px);
      }
      
      .close {
        color: #aaa;
        font-size: 20px;
        font-weight: bold;
        position: absolute;
        right: 15px;
        top: 15px;
      }
      
      .close:hover,
      .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
      }
      
      #androidLink, #iOSLink {
        width: 200px;
        display: inline-flex;
        align-items: center;
        
      }
      
      
      #androidLink > img, #iOSLink > img {
        width: 100%;
        height: auto;
        object-fit: cover;
       
      }
      
      
      .modal_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
      }
      
      .modal_title {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        height: auto;
        
      }
      
      .modal_title > img{
        width: 100%;
        height: auto;
        object-fit: cover;
      }
      
      .modal_text {
       font-size: 25px;

        span {
            font-size: 30px;
            color: var(--primary-color);
            padding:  0 5px;
        }
      }
      
      .modal_link {
      display: flex;
      
      }
  }
  

  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
    bottom: 45px !important;
  }
  
  .swiper-pagination .swiper-pagination-bullet-active {
    background: var(--primary-color) !important;

  }



